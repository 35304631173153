import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./layout.css"
import { rhythm } from "../utils/typography"
import ThemeContext from "../context/theme-context"

const HomeLink = ({ children, ...props }) => (
  <Link
    style={{
      boxShadow: `none`,
      textDecoration: `none`,
      color: `inherit`,
    }}
    to={`/`}
    {...props}
  >
    {children}
  </Link>
)

const ThemeToggle = ({ theme, onToggle }) => (
  <label className="toggle-wrapper">
    <input type="checkbox" checked={theme === "dark"} onChange={onToggle} />
    <div className="toggle"></div>
  </label>
)

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const { theme, toggleTheme } = useContext(ThemeContext)
  let header

  if (location.pathname === rootPath) {
    header = (
      <div className="flex justify-between mt-6">
        <h1 className="text-2xl">
          <HomeLink>{title}</HomeLink>
        </h1>
        <ThemeToggle theme={theme} onToggle={toggleTheme} />
      </div>
    )
  } else {
    header = (
      <div className="flex justify-between mt-6">
        <h3 className="home-link">
          <HomeLink>{title}</HomeLink>
        </h3>
        <ThemeToggle theme={theme} onToggle={toggleTheme} />
      </div>
    )
  }
  return (
    <div
      className={theme === "dark" ? "dark" : "light"}
      style={{ minHeight: `100vh` }}
    >
      <div
        className="mx-auto"
        style={{
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          <small>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </small>
        </footer>
      </div>
    </div>
  )
}

HomeLink.propTypes = {
  children: PropTypes.node.isRequired
}

ThemeToggle.propTypes = {
  theme: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Layout
